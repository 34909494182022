﻿'use strict'

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeSiteSearch
    * @description Setup the search for the site.  Event handlers, etc
    **/
    var initializeSiteSearch = function () {

        var $searchToggle = $("#search-toggle");
        var $close = $(".close-search");
        $searchToggle.add($close).on("click", function () {
            var $searchDropdown = $('.search-dropdown');
            $searchDropdown.toggleClass('search-open')
            var openSearchLabel = $('.header-search-button').attr('data-open-aria-label');
            var closeSearchLabel = $('.header-search-button').attr('data-close-aria-label');
            $('.header-search-button').attr('aria-label', $searchDropdown.hasClass('search-open') ? closeSearchLabel : openSearchLabel);
            $searchDropdown.attr('aria-expanded', function (idx, attr) { return !JSON.parse(attr); });
        });
        
    };

    // Return an object exposing the public items
    return {
        initialize: initializeSiteSearch
    };

}());