﻿'use strict'

var bootstrap = require('bootstrap');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeModelShowEvent
    * @description Setup the popup/ Bootstrap Modal event handler
    **/
    var initializeModelShowEvent = function () {

        var modalList = document.querySelectorAll('.modal')

        modalList.forEach(function (modalPopup) {

            ///////////////////////////////
            // Create or get the Bootstrap modal object to verify it is created
            var modalElement = bootstrap.Modal.getOrCreateInstance(modalPopup); // Returns a Bootstrap modal instance

            ///////////////////////////////
            // Look for links or buttons with the CSS Class specifed to help with the wireup
            var externalLinksWithClass = document.querySelectorAll("." + modalPopup.getAttribute("data-css-class"));

            //console.log("externalLinksWithClass count: " + externalLinksWithClass.length);

            externalLinksWithClass.forEach(function (externalLink) {
                externalLink.addEventListener('click', function (event) {
                    modalElement.show(externalLink);
                    event.preventDefault();
                    return false;
                });
            });

            ///////////////////////////////
            // Handle the show event to set the url to either the data-bs-externalurl or href attribute
            modalPopup.addEventListener('show.bs.modal', function (event) {
                // Button that triggered the modal
                var button = event.relatedTarget;
                // Extract info from data-bs-* attributes
                var externalurl = button.getAttribute('href');
                if (button.hasAttribute('data-bs-externalurl')) {
                    externalurl = button.getAttribute('data-bs-externalurl');
                }
                // Update the modal's content.
                var externalurlButton = modalPopup.querySelector('.external-url');

                externalurlButton.setAttribute('href', externalurl);
            });
        });


    };

    // Return an object exposing the public items
    return {
        initialize: initializeModelShowEvent
    };

}());