﻿'use strict'

module.exports = (function () {

    // Set login URLs - taken from this file https://www.upmchealthplan.com/js/upmchp.js

    // These are defaults and will be set via data properties in the markup below
    var memLoginURL = "https://mcnet.upmchp.com/memberportal/#/login-with?login_hint=";
    var producerLoginURL = "https://www.upmchpsalesportal.com/Portal/Home.aspx?login_hint=";
    var empLoginURL = "https://upmchealthplan.upmc.com/Employer/Home.aspx?login_hint="; //Employer prod
    var providerLoginURL = "https://upmchealthplan.upmc.com/Login/Login.aspx?CLIENT=000001&ID=000001&DIV=0001&user="; //Provider prod

    // private methods

    /**
    * @ngdoc method
    * @name returnLoginUrl
    * @description Get the correct Login Url from the currently selected option
    * @return Login Url for the login button
    **/
    var returnLoginUrl = function (selectorObj, loginUrlDefault) {
        
        console.log("data-login-url: " + selectorObj.selectedOptions[0].getAttribute('data-login-url'));
        if (selectorObj.selectedOptions[0].getAttribute('data-login-url')) {
            return selectorObj.selectedOptions[0].getAttribute('data-login-url');
        }

        return loginUrlDefault;
    };

    /**
    * @ngdoc method
    * @name hideAllAlertMessages
    * @description Hides all elements with the class "alert-message"
    **/
    var hideAllAlertMessages = function() {
        var elems = document.querySelectorAll('.alert-message');
        for (var index = 0; index < elems.length; index++) {
            elems[index].style.display = "none";
        }
    };

    // public methods

    /**
    * @ngdoc method
    * @name initializePopupInteractivity
    * @description Setup the popup message changes and sign in destination
    **/
    var initializePopupInteractivity = function () {

        var userName = document.querySelector(".user-id");
        var loginButton = document.querySelector(".login-button");
        var typeSelectorDropdown = document.querySelector(".login-type-selection");
        var forgotLink = document.querySelector('.forgot-link');
        hideAllAlertMessages();
        loginButton.href = returnLoginUrl(typeSelectorDropdown, memLoginURL);

        userName.addEventListener('keyup', function (event) {
            var errorUsername = document.querySelector(".no-username");
            errorUsername.style.display = "none";
        });

        loginButton.addEventListener('click', function (event) {

            console.log("login-button clicked");

            if (userName.value.length == 0) {
                console.log("error: no username");

                var errorUsername = document.querySelector(".no-username");
                errorUsername.style.display = "block";

                event.preventDefault();
                return false;
            }
            else {
                loginButton.setAttribute('href', loginButton.href + userName.value);
            }

        });

        typeSelectorDropdown.addEventListener('change', function (event) {

            console.log("login-type-selection changed");

            switch (this.value) {
                case 'member':
                    forgotLink.style.display = "block";

                    hideAllAlertMessages();

                    loginButton.setAttribute('href', returnLoginUrl(typeSelectorDropdown, memLoginURL));
                    break;

                case 'employer':
                    forgotLink.style.display = "none";

                    hideAllAlertMessages();
                    var alertMessage = document.querySelector(".alert-employer");
                    alertMessage.style.display = "block";

                    loginButton.setAttribute('href', returnLoginUrl(typeSelectorDropdown, empLoginURL));
                    break;

                case 'provider':
                    forgotLink.style.display = "none";

                    hideAllAlertMessages();
                    var alertMessage = document.querySelector(".alert-provider");
                    alertMessage.style.display = "block";

                    loginButton.setAttribute('href', returnLoginUrl(typeSelectorDropdown, providerLoginURL) );
                    break;

                case 'producer':
                    forgotLink.style.display = "none";

                    hideAllAlertMessages();
                    var alertMessage = document.querySelector(".alert-producer");
                    alertMessage.style.display = "block";

                    loginButton.setAttribute('href', returnLoginUrl(typeSelectorDropdown, producerLoginURL) );
                    break;

                default:
                    forgotLink.style.display = "none";

                    hideAllAlertMessages();
                    var alertMessage = document.querySelector(".alert-default");
                    alertMessage.style.display = "block";

                    break;
            }
        });



    };



    // Return an object exposing the public items
    return {
        initialize: initializePopupInteractivity
    };

}());